import React, {
	FunctionComponent,
	useContext,
	useEffect,
	useState,
} from 'react';
import { HTTPContext } from '../context/http';
import WelcomeHero from '../components/WelcomeHero';
import CompaniesTiles, {
	HomepageCompaniesResponse,
} from '../components/CompaniesTiles';

const Homepage: FunctionComponent = () => {
	const http = useContext(HTTPContext);
	const [companies, setCompanies] = useState<HomepageCompaniesResponse[]>([]);

	useEffect(() => {
		http
			.get<HomepageCompaniesResponse[]>('/companies/homepage')
			.then((response) => setCompanies(response.data));
	}, [http]);

	return (
		<main>
			<WelcomeHero />
			<CompaniesTiles companies={companies} />
		</main>
	);
};

export default Homepage;
