import React from 'react';
import {
	Box,
	Button,
	GridItem,
	Heading,
	SimpleGrid,
	Stack,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';

export const FormSection: React.FC<{ name: string; description: string }> = ({
	children,
	name,
	description,
}) => (
	<>
		<SimpleGrid
			display={{ base: 'initial', md: 'grid' }}
			columns={{ md: 3 }}
			spacing={{ md: 6 }}
		>
			<GridItem colSpan={{ md: 1 }}>
				<Box px={[4, 0]}>
					<Heading fontSize="lg" fontWeight="md" lineHeight="6">
						{name}
					</Heading>
					<Text
						mt={1}
						fontSize="sm"
						color={useColorModeValue('gray.600', 'gray.400')}
					>
						{description}
					</Text>
				</Box>
			</GridItem>
			<GridItem mt={[5, null, 0]} colSpan={{ md: 2 }}>
				<Box shadow="base" rounded={[null, 'md']} overflow={{ sm: 'hidden' }}>
					<Stack
						px={4}
						py={5}
						bg={useColorModeValue('white', 'gray.700')}
						spacing={6}
						p={{ sm: 6 }}
					>
						{children}
					</Stack>
					<Box
						px={{ base: 4, sm: 6 }}
						py={3}
						bg={useColorModeValue('gray.50', 'gray.900')}
						textAlign="right"
					>
						<Button
							type="submit"
							colorScheme="brand"
							_focus={{ shadow: '' }}
							fontWeight="md"
						>
							Save
						</Button>
					</Box>
				</Box>
			</GridItem>
		</SimpleGrid>
	</>
);

export const FormSectionSeparator: React.FC = () => (
	<Box visibility={{ base: 'hidden', sm: 'visible' }} aria-hidden="true">
		<Box py={5}>
			<Box
				borderTop="solid 1px"
				borderTopColor={useColorModeValue('gray.300', 'whiteAlpha.300')}
			/>
		</Box>
	</Box>
);
