import React, { FunctionComponent, useContext } from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import UserSettings from '../components/UserSettings';
import CompanySettings from '../components/CompanySettings';
import UserCompaniesList from '../components/UserCompaniesList';
import UsersList from '../components/UsersList';
import { AuthContext } from '../context/auth';
import { UserPrivileges } from '../interfaces/User';
import {
	Box,
	Flex,
	Tab,
	TabList,
	Tabs,
	useColorModeValue,
} from '@chakra-ui/react';

const Settings: FunctionComponent = () => {
	const { url } = useRouteMatch();
	const { user } = useContext(AuthContext);

	const accessToComanies =
		user?.privileges.includes(UserPrivileges.ManageCompaniesSettings) ||
		user?.privileges.includes(UserPrivileges.ManageOwnCompanySettings);
	const manageUsers = user?.privileges.includes(UserPrivileges.ManageUsers);

	return (
		<Box
			bg={useColorModeValue(
				'background.subsectionLight',
				'background.subsectionDark'
			)}
		>
			<Flex
				alignItems="center"
				justifyContent="space-between"
				borderWidth={0}
				overflowX="auto"
			>
				<Tabs borderBottomColor="transparent" maxW={'container.lg'} margin={'auto'}>
					<TabList>
						<Tab py={4} m={0} _focus={{ boxShadow: 'none' }} as={Link} to={url}>
							Profil
						</Tab>
						{accessToComanies && (
							<Tab
								py={4}
								m={0}
								_focus={{ boxShadow: 'none' }}
								as={Link}
								to={`${url}/firma`}
							>
								Firmy
							</Tab>
						)}
						{manageUsers && (
							<Tab
								py={4}
								m={0}
								_focus={{ boxShadow: 'none' }}
								as={Link}
								to={`${url}/uzytkownicy`}
							>
								Użytkownicy
							</Tab>
						)}
					</TabList>
				</Tabs>
			</Flex>
			<Switch>
				<Route exact path={url} component={UserSettings} />
				<Route exact path={`${url}/firma`} component={UserCompaniesList} />
				<Route path={`${url}/firma/:company`} component={CompanySettings} />
				<Route exact path={`${url}/uzytkownicy`} component={UsersList} />
				<Route path={`${url}/uzytkownicy/:user`} component={UserSettings} />
			</Switch>
		</Box>
	);
};

export default Settings;
