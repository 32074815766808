import React, { FunctionComponent } from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import {
	Box,
	Container,
	Link,
	ListItem,
	OrderedList,
	Text,
} from '@chakra-ui/react';
import { FormSectionSeparator } from './Forms/FormSection';

interface Props {}

const UserCompaniesList: FunctionComponent<Props> = () => {
	const { url } = useRouteMatch();

	return (
		<Box p={10}>
			<Container maxW={'container.lg'}>
				<Text fontSize={'3xl'} as="h3" fontWeight="bold">
					Wybierz Firmę
				</Text>
				<FormSectionSeparator />
				<OrderedList>
					<ListItem>
						<Link as={RouterLink} to={`${url}/firma_1`}>
							firma 1
						</Link>
					</ListItem>
					<ListItem>
						<Link as={RouterLink} to={`${url}/firma_2`}>
							firma 2
						</Link>
					</ListItem>
					<ListItem>
						<Link as={RouterLink} to={`${url}/firma_3`}>
							firma 3
						</Link>
					</ListItem>
				</OrderedList>
			</Container>
		</Box>
	);
};

export default UserCompaniesList;
