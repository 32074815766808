import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './pages/Login';
import LogOut from './pages/LogOut';
import SingleCard from './pages/SingleCard';
import CardCompany from './pages/CardCompany';
import Homepage from './pages/Homepage';
import Settings from './pages/Settings';
import { LinkLogIn, LinkLogOut, LinkSettings } from './config/links';

const Routes: FunctionComponent = () => (
	<Switch>
		<Route path={LinkLogIn} component={Login} />
		<Route path={LinkLogOut} component={LogOut} />
		<Route path="/card/:company/:cardId" component={SingleCard} />
		<Route path="/card/:company/" component={CardCompany} />
		<Route path={LinkSettings} component={Settings} />
		<Route path="/" component={Homepage} />
	</Switch>
);

export default Routes;
