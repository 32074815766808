import { User } from '../../context/auth';

export const fetchUserById = (id: string): Promise<User> =>
	new Promise<User>((resolve) => {
		setTimeout(() => {
			resolve({
				auth: true,
				user: {
					privileges: [],
					displayName: 'mock ' + id,
					profileImageUrl: '',
					name: '',
					googleId: '',
				},
			});
		}, 1000);
	});
