import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CompanySettingsSection from './CompanySettingsSection';
import { CompanyModel } from '../interfaces/Company';
import { BigSpinner } from './BigSpinner';

const CompanySettings: FunctionComponent = () => {
	const { company } = useParams<{ company: string }>();
	const [companyData, setCompanyData] = useState<CompanyModel | undefined>();

	useEffect(() => {
		setCompanyData({
			displayName: 'test',
			slug: company,
			employersIds: [],
			cardsConfig: [],
		});
	}, [company]);

	return companyData ? (
		<CompanySettingsSection comapny={companyData} />
	) : (
		<BigSpinner />
	);
};

export default CompanySettings;
