import React, { FunctionComponent } from 'react';
import { Box, Container, Text } from '@chakra-ui/react';
import { FormSection, FormSectionSeparator } from './Forms/FormSection';
import { User } from '../context/auth';
import { UserPrivileges } from '../interfaces/User';
import {
	CheckboxOptionProps,
	FormCheckboxArray,
} from './Forms/FormCheckboxArray';
import { FormTextInput } from './Forms/FormTextInput';

interface Props {
	user: User;
	ownProfile: boolean;
}

const privilegesToView: CheckboxOptionProps[] = [
	{
		name: UserPrivileges.ManageUsers,
		description: 'ManageUsers',
		displayName: 'ManageUsers',
		active: false,
	},
	{
		name: UserPrivileges.ManageCompaniesStamps,
		description: 'ManageCompaniesStamps',
		displayName: 'ManageCompaniesStamps',
		active: false,
	},
	{
		name: UserPrivileges.ManageOwnCompanySettings,
		description: 'ManageOwnCompanySettings',
		displayName: 'ManageOwnCompanySettings',
		active: false,
	},
	{
		name: UserPrivileges.ManageOwnCompanyStamps,
		description: 'ManageOwnCompanyStamps',
		displayName: 'ManageOwnCompanyStamps',
		active: false,
	},
	{
		name: UserPrivileges.ManageCompaniesSettings,
		description: 'ManageCompaniesSettings',
		displayName: 'ManageCompaniesSettings',
		active: false,
	},
];

const UserSettingSection: FunctionComponent<Props> = ({ user, ownProfile }) => {
	const privileges: CheckboxOptionProps[] = privilegesToView.map((el) => ({
		...el,
		active: user.user?.privileges.includes(el.name) || false,
	}));

	const title = ownProfile
		? 'Ustawienia konta'
		: `Ustawienia dla konta: ${user.user?.displayName || user.user?.name}`;

	const description = ownProfile
		? 'tu możesz ustawić podstawowe dane dotyczące twojego konta'
		: `edytuj konto użytkownika ${user.user?.displayName || user.user?.name}`;

	return (
		<Box as="section" p={10}>
			<Container maxW={'container.lg'}>
				<Text fontSize={'3xl'} as="h3" fontWeight="bold">
					{title}
				</Text>
				<FormSectionSeparator />
				<FormSection name="Konto" description={description}>
					<FormTextInput
						label="Nazwa profilu"
						name="displayName"
						value={user.user?.displayName || ''}
					/>
				</FormSection>

				{user.user?.privileges.includes(UserPrivileges.ManageUsers) && (
					<>
						<FormSectionSeparator />
						<FormSection
							name="Uprawnienia"
							description="Nadaj lub usuń uprawnienia dla danego użytkownika"
						>
							<FormCheckboxArray
								label="Uprawienia"
								name="privileges"
								options={privileges}
							/>
						</FormSection>
					</>
				)}
			</Container>
		</Box>
	);
};

export default UserSettingSection;
