import React, { useContext } from 'react';
import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
} from '@chakra-ui/react';
import { AuthContext } from '../context/auth';

export const Unauthorized: React.FC = () => {
	const { auth } = useContext(AuthContext);
	return (
		<Alert
			status="error"
			variant="subtle"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			textAlign="center"
			height="200px"
		>
			<AlertIcon boxSize="40px" mr={0} />
			<AlertTitle mt={4} mb={1} fontSize="lg">
				Brak dostępu
			</AlertTitle>
			<AlertDescription maxWidth="sm">
				{auth ? 'nie masz dostępu do tego zasobu' : 'zaloguj się'}
			</AlertDescription>
		</Alert>
	);
};
