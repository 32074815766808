import React, { FunctionComponent } from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import {
	Box,
	Container,
	Link,
	ListItem,
	OrderedList,
	Text,
} from '@chakra-ui/react';
import { FormSectionSeparator } from './Forms/FormSection';

interface Props {}

const UsersList: FunctionComponent<Props> = () => {
	const { url } = useRouteMatch();

	return (
		<Box p={10}>
			<Container maxW={'container.lg'}>
				<Text fontSize={'3xl'} as="h3" fontWeight="bold">
					Wybierz uzytkownika
				</Text>
				<FormSectionSeparator />
				<OrderedList>
					<ListItem>
						<Link as={RouterLink} to={`${url}/janusz_1`}>
							janusz 1
						</Link>
					</ListItem>
					<ListItem>
						<Link as={RouterLink} to={`${url}/janusz_2`}>
							janusz 2
						</Link>
					</ListItem>
					<ListItem>
						<Link as={RouterLink} to={`${url}/janusz_3`}>
							janusz 3
						</Link>
					</ListItem>
				</OrderedList>
			</Container>
		</Box>
	);
};

export default UsersList;
