import React, {
	FunctionComponent,
	useContext,
	useEffect,
	useState,
} from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { HTTPContext } from '../context/http';
import cardStorage from '../utils/cardStorage';

interface PostCardResponse {
	id: string;
}

const CardCompany: FunctionComponent = () => {
	const http = useContext(HTTPContext);
	const { company } = useParams<{ company: string; cardId: string }>();
	const [cardId, setCardId] = useState<string>('');
	const storedCard = cardStorage(company);

	useEffect(() => {
		const storedId = storedCard.get();
		if (storedId) {
			setCardId(storedId);
		} else {
			http
				.post<PostCardResponse>('/cards/card/create', { company })
				.then(({ data }) => {
					storedCard.put(data.id);
					setTimeout(() => {
						setCardId(data.id);
					}, 1000);
				})
				.catch(() => {
					window.location.href = '/';
				});
		}
	}, [company, http, storedCard]);
	return (
		<main>
			<section>
				{cardId === '' ? (
					'Tworzę dla Ciebie nową kartę...'
				) : (
					<Redirect to={`/card/${company}/${cardId}`} />
				)}
			</section>
		</main>
	);
};

export default CardCompany;
