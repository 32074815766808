import React, { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import cardStorage from '../utils/cardStorage';
import { Badge, Box, chakra, Image, useColorModeValue } from '@chakra-ui/react';

interface Props {
	displayName: string;
	slug: string;
	logoUrl?: string;
}

const cardImagePlaceholder = 'https://via.placeholder.com/320x150';

const CompanyTile: FunctionComponent<Props> = ({
	displayName,
	logoUrl,
	slug,
}) => {
	const storedCard = cardStorage(slug);
	return (
		<Box p={5} as={RouterLink} to={`/card/${slug}`}>
			<Box
				maxW="xs"
				mx="auto"
				bg={useColorModeValue('white', 'gray.800')}
				shadow="lg"
				rounded="lg"
			>
				<Box px={4} py={2}>
					<chakra.h1
						color={useColorModeValue('gray.800', 'white')}
						fontWeight="bold"
						fontSize="3xl"
						textTransform="uppercase"
						style={{
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
						}}
					>
						{displayName}
					</chakra.h1>
				</Box>

				<Box position="relative">
					{storedCard.get() && (
						<Badge position="absolute" top={2} right={2} colorScheme="purple">
							Zapisane
						</Badge>
					)}
					<Image
						h={150}
						w={320}
						fit="cover"
						mt={2}
						src={logoUrl}
						alt={displayName}
						backgroundColor={useColorModeValue('gray.800', 'white')}
						fallbackSrc={cardImagePlaceholder}
						roundedBottom="lg"
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default CompanyTile;
