import React, {
	FunctionComponent,
	useContext,
	useEffect,
	useState,
} from 'react';
import Card from '../components/Card';
import QrCode from 'qrcode.react';
import { useParams } from 'react-router-dom';
import { HTTPContext } from '../context/http';
import { AuthContext } from '../context/auth';
import { UserPrivileges } from '../interfaces/User';
import { AxiosResponse } from 'axios';
import cardStorage from '../utils/cardStorage';

const buildStampsArray = (stamps: boolean[]) =>
	stamps.map((stamp, index) => ({
		checked: stamp,
		id: `card_${String.fromCharCode(97 + index)}`,
	}));

interface CardResponse {
	id: string;
	stamps: boolean[];
	displayName: string;
}

enum Status {
	loading,
	valid,
	notExist,
	invalid,
}

const SingleCard: FunctionComponent = () => {
	const http = useContext(HTTPContext);
	const [status, setStatus] = useState<Status>(Status.loading);
	const [stamps, updateStamps] = useState<{ checked: boolean; id: string }[]>(
		[]
	);
	const [title, setTitle] = useState<string>('...');
	const { cardId, company } = useParams<{ cardId: string; company: string }>();
	const auth = useContext(AuthContext);
	const [canEdit, setCanEdit] = useState(false);
	const storedCard = cardStorage(company);

	const cardLoaded = (response: AxiosResponse<CardResponse>) => {
		updateStamps(buildStampsArray(response.data.stamps));
		setTitle(response.data.displayName);
		setStatus(Status.valid);
		storedCard.put(response.data.id);
	};
	const cardLoadingFail = (reason: any) => {
		const status = reason.response.data.message;
		if (status === 'card does not exist') {
			setStatus(Status.notExist);
		}
		if (status === 'invalid id') {
			setStatus(Status.notExist);
		}
	};

	const updateFail = (reason: any) => {
		window.location.reload();
	};

	useEffect(() => {
		setStatus(Status.loading);
		http
			.get<CardResponse>(`/cards/card/${cardId}`)
			.then(cardLoaded)
			.catch(cardLoadingFail);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardId, http]);
	useEffect(() => {
		setCanEdit(
			!!auth.user?.privileges.includes(UserPrivileges.ManageCompaniesStamps)
		);
	}, [auth]);

	const update = (id: string, state: boolean): void => {
		setStatus(Status.loading);
		if (canEdit) {
			http
				.put(`/cards/card/${cardId}`, {
					stamps: stamps.map((stamp) => (stamp.id === id ? !state : stamp.checked)),
				})
				.then(cardLoaded)
				.catch(updateFail);
		}
	};

	return (
		<main>
			{status === Status.loading && (
				<header>
					<h4>Wczytuję...</h4>
				</header>
			)}
			{status === Status.valid && (
				<>
					<section>
						<Card
							allowChange={canEdit}
							title={title}
							stamps={stamps}
							onChange={update}
						/>
					</section>
					<header>
						{storedCard.get() && <p>Karta zapisana w pamięci urządzenia</p>}
						<p>pokaż kod sprzedawcy</p>
						<QrCode value={window.location.href} size={250} />
						<p>{cardId}</p>
					</header>
				</>
			)}
			{status === Status.notExist && (
				<header>
					<h2>Podana karta nie istnieje</h2>
				</header>
			)}
			{status === Status.invalid && (
				<header>
					<h2>Podana karta nie istnieje</h2>
				</header>
			)}
		</main>
	);
};

export default SingleCard;
