import React from 'react';
import {
	FormControl,
	FormLabel,
	GridItem,
	Input,
	useColorModeValue,
} from '@chakra-ui/react';

export const FormTextInput: React.FC<{
	name: string;
	label: string;
	value: string;
	disabled?: boolean;
}> = ({ name, label, value, disabled }) => (
	<FormControl as={GridItem} colSpan={[6, 4]}>
		<FormLabel
			htmlFor="email_address"
			fontSize="sm"
			fontWeight="md"
			color={useColorModeValue('gray.700', 'gray.50')}
		>
			{label}
		</FormLabel>
		<Input
			disabled={disabled}
			type="text"
			name={name}
			id={name}
			autoComplete="email"
			mt={1}
			focusBorderColor="brand.400"
			shadow="sm"
			size="sm"
			w="full"
			rounded="md"
			defaultValue={value}
		/>
	</FormControl>
);
