import React from 'react';
import {
	Box,
	chakra,
	Checkbox,
	Flex,
	Stack,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { UserPrivileges } from '../../interfaces/User';

export interface CheckboxOptionProps {
	name: UserPrivileges;
	description: string;
	displayName: string;
	active: boolean;
}

const CheckboxOption: React.FC<CheckboxOptionProps> = ({
	displayName,
	name,
	description,
	active,
}) => (
	<Flex alignItems="start">
		<Flex alignItems="center" h={5}>
			<Checkbox
				colorScheme="brand"
				id={name}
				rounded="md"
				defaultIsChecked={active}
			/>
		</Flex>
		<Box ml={3} fontSize="sm">
			<chakra.label
				htmlFor={name}
				fontWeight="md"
				color={useColorModeValue('gray.700', 'gray.50')}
			>
				{displayName}
			</chakra.label>
			<Text color={useColorModeValue('gray.500', 'gray.400')}>{description}</Text>
		</Box>
	</Flex>
);

export const FormCheckboxArray: React.FC<{
	name: string;
	label: string;
	options: CheckboxOptionProps[];
}> = ({ label, options }) => (
	<chakra.fieldset>
		<Box
			as="legend"
			fontSize="md"
			color={useColorModeValue('gray.900', 'gray.50')}
		>
			{label}
		</Box>
		<Stack mt={4} spacing={4}>
			{options.map((option) => (
				<CheckboxOption {...option} key={option.name} />
			))}
		</Stack>
	</chakra.fieldset>
);
