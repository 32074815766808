import React, {
	FunctionComponent,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext, User } from '../context/auth';
import { UserPrivileges } from '../interfaces/User';
import { fetchUserById } from '../utils/api/users';
import UserSettingSection from './UserSettingSection';
import { BigSpinner } from './BigSpinner';
import { Unauthorized } from './Unauthorized';

const UserSettings: FunctionComponent = () => {
	const params = useParams<{ user: string }>();
	const auth = useContext(AuthContext);
	const [loading, setLoading] = useState<boolean>(true);
	const [user, setUser] = useState<User>();
	const [manageOwn, setManageOwn] = useState<boolean>(false);

	useEffect(() => {
		if (!auth) {
			return;
		}
		if (
			params.user &&
			auth.user?.privileges.includes(UserPrivileges.ManageUsers)
		) {
			fetchUserById(params.user)
				.then((user) => setUser(user))
				.finally(() => {
					setManageOwn(false);
					setLoading(false);
				});
		} else {
			setUser(auth);
			setManageOwn(true);
			setLoading(false);
		}
	}, [auth, params]);

	if (loading) {
		return <BigSpinner />;
	}

	return user?.auth ? (
		<UserSettingSection user={user} ownProfile={manageOwn} />
	) : (
		<Unauthorized />
	);
};

export default UserSettings;
