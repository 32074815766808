import React, { FunctionComponent } from 'react';
import { REACT_APP_GATEWAY } from '../config/envs';
import { BigSpinner } from '../components/BigSpinner';

const LogOut: FunctionComponent = () => {
	window.location.href = `${REACT_APP_GATEWAY}/auth/logout`;
	return <BigSpinner />;
};

export default LogOut;
