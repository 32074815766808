import React, { FunctionComponent } from 'react';
import { REACT_APP_GATEWAY } from '../config/envs';
import { Box, Button, Center, Text } from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';

const Login: FunctionComponent = () => {
	return (
		<main>
			<Box px={8} py={24} mx="auto">
				<Box w="full" mx="auto" textAlign={{ base: 'left', md: 'center' }}>
					<Center p={8}>
						<Button
							as="a"
							w={'full'}
							maxW={'md'}
							variant={'outline'}
							href={`${REACT_APP_GATEWAY}/auth/google`}
							leftIcon={<FcGoogle />}
						>
							<Center>
								<Text>Zaloguj za pomocą Google</Text>
							</Center>
						</Button>
					</Center>
				</Box>
			</Box>
		</main>
	);
};

export default Login;
