import React, { FunctionComponent } from 'react';
import './Card.scss';

interface Stamp {
	checked: boolean;
	id: string;
}

interface Props {
	allowChange: boolean;
	title: string;
	stamps: Stamp[];
	onChange: (stampId: string, state: boolean) => void;
}

const Card: FunctionComponent<Props> = ({
	title,
	stamps,
	allowChange,
	onChange,
}) => {
	const toggle = (stamp: Stamp) => () => onChange(stamp.id, stamp.checked);
	return (
		<div className="card">
			<h4 className="card__title">{title}</h4>
			<div
				className={`card__stamps stamps ${allowChange && 'stamps--allow-edit'}`}
			>
				{stamps.map((stamp) => (
					<div className="stamps__item" key={stamp.id}>
						<input
							type="checkbox"
							checked={stamp.checked}
							className="stamps__item"
							onChange={toggle(stamp)}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default Card;
