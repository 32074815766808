import React, { FunctionComponent } from 'react';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import CompanyTile from './CompanyTile';

export interface HomepageCompaniesResponse {
	displayName: string;
	slug: string;
	logoUrl?: string;
	_id: string;
}

interface Props {
	companies: HomepageCompaniesResponse[];
}

const CompaniesTiles: FunctionComponent<Props> = ({ companies }) => {
	return (
		<Flex
			w="full"
			alignItems="center"
			justifyContent="center"
			flexWrap="wrap"
			bg={useColorModeValue('#F9FAFB', 'gray.600')}
		>
			{companies.map((company) => (
				<CompanyTile
					key={company._id}
					displayName={company.displayName}
					slug={company.slug}
					logoUrl={company.logoUrl}
				/>
			))}
		</Flex>
	);
};

export default CompaniesTiles;
