import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AuthContext } from '../context/auth';
import {
	Avatar,
	Box,
	Button,
	Flex,
	HStack,
	IconButton,
	Image,
	Link,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Stack,
	useColorMode,
	useColorModeValue,
	useDisclosure,
} from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
	LinkLogIn,
	LinkLogOut,
	LinkMyCards,
	LinkSettings,
} from '../config/links';
import logoLight from '../assets/logo-simple.png';
import logoDark from '../assets/logo-simple_dark.png';

interface NavLinkType {
	name: string;
	to: string;
}

const MainLinks: NavLinkType[] = [];

const NavLink: React.FC<NavLinkType> = ({ name, to }) => (
	<Link
		px={2}
		py={1}
		rounded={'md'}
		_hover={{
			textDecoration: 'none',
			bg: useColorModeValue('gray.200', 'gray.700'),
		}}
		href={to}
	>
		{name}
	</Link>
);

function Header() {
	const auth = useContext(AuthContext);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { colorMode, toggleColorMode } = useColorMode();

	return (
		<Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
			<Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
				{!!MainLinks.length && (
					<IconButton
						size={'md'}
						icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
						aria-label={'Open Menu'}
						display={{ md: 'none' }}
						onClick={isOpen ? onClose : onOpen}
					/>
				)}
				<HStack spacing={8} alignItems={'center'}>
					<Box>
						<RouterLink to="/">
							<Image
								src={colorMode === 'light' ? logoLight : logoDark}
								alt="zbier.am"
								height="16"
							/>{' '}
						</RouterLink>
					</Box>
					{!!MainLinks.length && (
						<HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
							{MainLinks.map((link) => (
								<NavLink key={link.name} name={link.name} to={link.to} />
							))}
						</HStack>
					)}
				</HStack>
				<Flex alignItems={'center'}>
					<IconButton
						onClick={toggleColorMode}
						mx={5}
						icon={colorMode === 'dark' ? <SunIcon /> : <MoonIcon />}
						aria-label={
							colorMode === 'light' ? 'zamień na tryb ciemny' : 'zamień na tryb jasny'
						}
					/>
					{auth.auth ? (
						<Menu>
							<MenuButton
								as={Button}
								rounded={'full'}
								variant={'link'}
								cursor={'pointer'}
								minW={0}
							>
								<Avatar
									size={'sm'}
									name={auth.user?.displayName}
									src={auth.user?.profileImageUrl}
								/>
							</MenuButton>
							<MenuList>
								<MenuItem as="span">
									{auth.user?.displayName || auth.user?.name}
								</MenuItem>
								<MenuDivider />
								<MenuItem as={RouterLink} to={LinkMyCards}>
									Moje karty
								</MenuItem>
								<MenuItem as={RouterLink} to={LinkSettings}>
									Ustawienia
								</MenuItem>
								<MenuDivider />
								<MenuItem as={RouterLink} to={LinkLogOut}>
									Wyloguj się
								</MenuItem>
							</MenuList>
						</Menu>
					) : (
						<Menu>
							<Button
								as={RouterLink}
								rounded={'full'}
								variant={'link'}
								cursor={'pointer'}
								minW={0}
								to={LinkLogIn}
							>
								Zaloguj się
							</Button>
						</Menu>
					)}
				</Flex>
			</Flex>

			{isOpen ? (
				<Box pb={4} display={{ md: 'none' }}>
					<Stack as={'nav'} spacing={4}>
						{MainLinks.map((link) => (
							<NavLink key={link.name} name={link.name} to={link.to} />
						))}
					</Stack>
				</Box>
			) : null}
		</Box>
	);
}

export default Header;
