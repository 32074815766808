import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Routes from './Routes';
import { BrowserRouter } from 'react-router-dom';
import { createHttpContext, HTTPContext } from './context/http';
import { AuthContext, User } from './context/auth';
import {
	ChakraProvider,
	ColorModeScript,
	CSSReset,
	extendTheme,
	ThemeConfig,
} from '@chakra-ui/react';
import Footer from './components/Footer';

const theme = extendTheme({
	initialColorMode: 'dark',
	useSystemColorMode: false,
	colors: {
		background: {
			light: '#F7FAFC',
			subsectionLight: '#dfe7eb',
			dark: '#4A5568',
			subsectionDark: '#656978',
		},
		brand: {
			50: '#e6eced',
			100: '#cdd9db',
			200: '#b3c6ca',
			300: '#9ab3b8',
			400: '#81a0a6',
			500: '#688d94',
			600: '#4f7a82',
			700: '#356771',
			800: '#1c545f',
			900: '#03414d',
		},
	},
} as ThemeConfig);

const App = () => {
	const [user, setUser] = useState<User>({ auth: false });
	const http = createHttpContext();

	useEffect(() => {
		http
			.get('/auth')
			.then((response) => {
				if (response.status === 200) return response.data;
				throw new Error('failed to authenticate user');
			})
			.then((responseJson) => {
				setUser({
					auth: responseJson.authenticated,
					user: responseJson.user,
				});
			})
			.catch(() => {
				setUser({
					auth: false,
				});
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ColorModeScript initialColorMode={theme.config.initialColorMode} />
			<HTTPContext.Provider value={http}>
				<AuthContext.Provider value={user}>
					<BrowserRouter>
						<ChakraProvider theme={theme}>
							<CSSReset />
							<Header />
							<Routes />
							<Footer />
						</ChakraProvider>
					</BrowserRouter>
				</AuthContext.Provider>
			</HTTPContext.Provider>
		</>
	);
};

export default App;
