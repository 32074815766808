import React, { FunctionComponent } from 'react';
import { Box, Container, Text } from '@chakra-ui/react';
import { FormSection, FormSectionSeparator } from './Forms/FormSection';
import { CompanyModel } from '../interfaces/Company';
import { FormTextInput } from './Forms/FormTextInput';
import { FormImageInput } from './Forms/FormImageInput';

interface Props {
	comapny: CompanyModel;
}

const CompanySettingsSection: FunctionComponent<Props> = ({ comapny }) => {
	return (
		<Box as="section" p={10}>
			<Container maxW={'container.lg'}>
				<Text fontSize={'3xl'} as="h3" fontWeight="bold">
					Zarządzaj firmą {comapny.displayName || comapny.slug}
				</Text>
				<FormSectionSeparator />
				<FormSection name="Konto" description="Ustawienia wyświetlania firmy">
					<FormTextInput
						label="Nazwa Pełna"
						name="displayName"
						value={comapny.displayName || ''}
					/>
					<FormTextInput
						label="Nazwa prosta, używana w adresie strony"
						name="slug"
						value={comapny.slug || ''}
					/>
					<FormImageInput name="Logo" label="logo" value={comapny.logoUrl} />
				</FormSection>
			</Container>
		</Box>
	);
};

export default CompanySettingsSection;
