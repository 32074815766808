import React from 'react';
import {
	Avatar,
	Button,
	Flex,
	FormControl,
	FormLabel,
	useColorModeValue,
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { FaUser } from 'react-icons/all';

export const FormImageInput: React.FC<{
	name: string;
	label: string;
	value?: string;
}> = ({ name, label, value }) => (
	<FormControl>
		<FormLabel
			fontSize="sm"
			fontWeight="md"
			color={useColorModeValue('gray.700', 'gray.50')}
		>
			{label}
		</FormLabel>
		<Flex alignItems="center" mt={1}>
			<Avatar
				boxSize={12}
				bg={useColorModeValue('gray.100', 'gray.800')}
				src={value}
				icon={
					<Icon
						as={FaUser}
						boxSize={9}
						mt={3}
						rounded="full"
						color={useColorModeValue('gray.300', 'gray.700')}
					/>
				}
			/>
			<Button
				type="button"
				ml={5}
				variant="outline"
				size="sm"
				fontWeight="medium"
				_focus={{ shadow: 'none' }}
			>
				{name}
			</Button>
		</Flex>
	</FormControl>
);
