export interface UserModel {
	name: string;
	displayName: string;
	googleId: string;
	profileImageUrl: string;
	privileges: UserPrivileges[];
}

export enum UserPrivileges {
	ManageOwnCompanyStamps = 'moc.stm',
	ManageOwnCompanySettings = 'moc.set',
	ManageUsers = 'man.usr',
	ManageCompaniesSettings = 'mac.set',
	ManageCompaniesStamps = 'mac.stm',
}
