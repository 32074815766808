import { createContext } from 'react';
import axios, { AxiosInstance } from 'axios';
import { REACT_APP_GATEWAY } from '../config/envs';

export const HTTPContext = createContext<AxiosInstance>(axios.create());

export const createHttpContext = (): AxiosInstance =>
	axios.create({
		baseURL: REACT_APP_GATEWAY,
		withCredentials: true,
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': 'true',
		},
	});
